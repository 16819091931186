import { MonitoringMetricsPluginApi, RequestBody, Resources } from './types';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

const HEADERS = {
  'Content-Type': 'application/json',
};

export class MyPluginBackendClient implements MonitoringMetricsPluginApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getMetrics(params: RequestBody): Promise<Resources> {
    let url = `${await this.discoveryApi.getBaseUrl(
      'monitoring-metrics',
    )}/metrics?`;

    url += `tags=${params.tags.flat()}&dateRequested=${
      params.date ? params.date : ''
    }`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: HEADERS,
    });
    return await this.handleResponse(response);
  }

  async getSummary(): Promise<any> {
    const response = await this.fetchApi.fetch(
      `${await this.discoveryApi.getBaseUrl('monitoring-metrics')}/summary`,
      {
        method: 'GET',
        headers: HEADERS,
      },
    );
    return await this.handleResponse(response);
  }

  async getDates(): Promise<string[]> {
    const data = await this.fetchApi.fetch(
      `${await this.discoveryApi.getBaseUrl('monitoring-metrics')}/dates`,
      {
        method: 'GET',
        headers: HEADERS,
      },
    );
    return await this.handleResponse(data);
  }

  async getTagMetrics(): Promise<any> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('monitoring-metrics');
    const response = await this.fetchApi.fetch(`${baseApiUrl}/tagMetrics`, {
      method: 'GET',
      headers: HEADERS,
    });
    return this.handleResponse(response);
  }

  private async handleResponse(response: Response) {
    if (!response.ok) {
      throw new Error();
    }
    return await response.json();
  }
}
