import { useCallback, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { doraApiRef, DoraMetricsPayload, FilteredDoraProject } from '../api';
import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import type { GetEntitiesByRefsResponse } from '@backstage/catalog-client';
import { StoredFilters } from '../components/Modals/ConfigurationDialog';

const handleError = (err: any) => {
  return Promise.reject(err);
};

export const GITHUB_DEPLOYMENT_JOB = 'github.com/deployment-job-name';
export const GITHUB_DEPLOYMENT_BRANCH = 'github.com/deployment-branch';
export const GITHUB_DEPLOYMENT_PIPELINE = 'github.com/deployment-pipeline';
export const GITHUB_REPO_SLUG = 'github.com/project-slug';
export const JIRA_COMPONENT_ANNOTATION = 'jira/component';
export const JIRA_PROJECT_KEY_ANNOTATION = 'jira/project-key';
export const DEFAULT_BRANCH_REGEX = /\/tree\/([^/]+)/;
export const DEFAULT_BRANCH = 'main';

export const useValidProjects = (
  entity: Entity | undefined,
): FilteredDoraProject | null => {
  if (entity === undefined) {
    return null;
  }

  return {
    hasMultipleOwners:
      (entity?.relations ?? []).filter(x => x.type === 'ownedBy').length > 1,
    jiraComponent:
      entity.metadata?.annotations?.[JIRA_COMPONENT_ANNOTATION] ||
      ('' as string),
    deploymentJobName:
      entity.metadata?.annotations?.[GITHUB_DEPLOYMENT_JOB] || ('' as string),
    deploymentPipeline:
      entity.metadata?.annotations?.[GITHUB_DEPLOYMENT_PIPELINE] || '',
    repositorySlug: entity.metadata?.annotations?.[GITHUB_REPO_SLUG] as string,
  };
};

export const useProjectInfo = (
  entity: Entity,
  projectKey: string,
  settings: StoredFilters,
): DoraFetchPayload => {
  const doraApi = useApi(doraApiRef);
  const catalogApi = useApi(catalogApiRef);
  const { workFlowType, entries } = settings;

  const getProjectDetails = useCallback(async () => {
    try {
      if (!settings) {
        return handleError('No settings available');
      }
      setTimeout(() => (document.activeElement as HTMLElement).blur(), 0);

      if (projectKey === undefined) {
        throw new Error(
          'This team has no Jira project key, unable to create DORA metrics',
        );
      }

      const filteredRefs = entity.relations
        ?.filter(relation => relation.targetRef.startsWith('component:'))
        .map(relation => relation.targetRef) as string[];

      const { items }: GetEntitiesByRefsResponse =
        await catalogApi.getEntitiesByRefs({ entityRefs: filteredRefs });

      if (items === undefined) {
        throw new Error('This team has no components');
      }

      const filteredProjects = items
        .map(useValidProjects)
        .filter(Boolean) as NonNullable<FilteredDoraProject>[];

      const period = '30d';
      const metrics = await doraApi.getDoraMetricsForProject({
        jiraProjectKey: projectKey,
        period,
        filteredProjects,
        compoundEntityRef: getCompoundEntityRef(entity),
        entityDoraSettings: {
          workFlowType: workFlowType,
          entries: settings.entries,
        },
      });
      return {
        metrics,
        filteredProjects,
        projectKey,
      };
    } catch (err: any) {
      return handleError(err);
    }
  }, [settings, entity, projectKey, workFlowType, catalogApi, doraApi]);

  const [state, fetchProjectInfo] = useAsyncFn(
    async () => await getProjectDetails(),
    [workFlowType, JSON.stringify(entries), projectKey],
  );

  useEffect(() => {
    fetchProjectInfo();
  }, [fetchProjectInfo]);
  return {
    projectLoading: state.loading,
    projectDoraMetrics: state.value,
    projectError: state.error,
  };
};

export type DoraFetchPayload = {
  projectLoading: boolean;
  projectDoraMetrics:
    | {
        metrics: DoraMetricsPayload;
        filteredProjects: FilteredDoraProject[];
        projectKey: string;
      }
    | undefined;
  projectError: any;
};
